import * as React from "react";
import Autosuggest, { ChangeEvent } from "react-autosuggest";
import { observer } from "mobx-react";
import {
  SEARCH_SECTIONS,
  SEARCH_SECTIONS_KEYS,
  SearchFilterKeys,
  SearchFilterProps,
} from "../../utils/search";

function renderSuggestion(suggestion: string) {
  return <span>{suggestion}</span>;
}

function renderSectionTitle(section: SearchFilterProps) {
  return <strong>{section.title}</strong>;
}

interface Props {
  performSearch: (key: SearchFilterKeys, value: string) => void;
}

interface State {
  value: string;
  suggestions: SearchFilterProps[];
}

const DEFAULT_STATE = {
  value: "",
  suggestions: [],
};

@observer
class SearchSuggestionBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  getSectionSuggestions = (section: SearchFilterProps | undefined): string[] => {
    if (section !== undefined) {
      return [String(section.val)];
    }
    return [];
  };

  onChange = (event: React.FormEvent<HTMLElement>, _: ChangeEvent) => {
    this.setState({ value: (event.currentTarget as HTMLInputElement).value });
  };

  onSuggestionsFetchRequested = (value: string) => {
    // Setting the string input as value for all sections
    const suggestions = SEARCH_SECTIONS.map((section) => {
      section.val = value;
      return section;
    });
    this.setState({ suggestions });
  };

  onSuggestionSelected = (suggestion: string, sectionIndex: number | null) => {
    if (sectionIndex === null) {
      return;
    }
    this.props.performSearch(SEARCH_SECTIONS_KEYS[sectionIndex], suggestion);
  };

  render() {
    const inputProps = {
      placeholder: "Type to search or filter",
      value: this.state.value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        multiSection={true}
        highlightFirstSuggestion={true}
        onSuggestionsFetchRequested={({ value }) => this.onSuggestionsFetchRequested(value)}
        onSuggestionsClearRequested={() => this.setState(DEFAULT_STATE)}
        onSuggestionSelected={(_e, { suggestion, sectionIndex }) =>
          this.onSuggestionSelected(suggestion, sectionIndex)
        }
        getSuggestionValue={(v) => v}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={this.getSectionSuggestions}
        inputProps={inputProps}
      />
    );
  }
}

export default SearchSuggestionBox;
