import * as React from "react";

import ATCCHomePage from "../components/pages/home/atcc";
import { NextSeo } from "next-seo";

// The Landing Page
const Index = () => {
  return (
    <>
      <NextSeo title="Home" />
      <ATCCHomePage />
    </>
  );
};

export default Index;
