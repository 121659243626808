import * as React from "react";
import { ReactSVG } from "react-svg";
import Link from "next/link";

interface GenomeTileProps {
  name: string;
  metadataText: string;
  idx?: number;
  uuid: string;
  iconName?: string;
}

export default class GenomeTile extends React.PureComponent<GenomeTileProps> {
  renderIcon = () => {
    let iconName = this.props.iconName;
    if (!iconName) {
      switch (this.props.idx) {
        case 0:
          iconName = "spike";
        case 1:
          iconName = "jerry";
          break;
        case 2:
          iconName = "tom";
          break;
      }
    }

    return <ReactSVG src={`../../../static/${iconName}.svg`} />;
  };

  render() {
    return (
      <Link as={`/genomes/${this.props.uuid}`} href={`/genomes/[uuid]`}>
        <div className="genome-tile">
          <span className="genome-icon">{this.renderIcon()}</span>
          <div className="genome-info">
            <span>{this.props.name}</span>
            <span className="genome-subtitle">{this.props.metadataText}</span>
          </div>
        </div>
      </Link>
    );
  }
}
